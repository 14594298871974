import React, {useState} from 'react'
import Server from './Server';
import Website from './Website';
import undraw_server from '../assets/undraw_server.svg'
import undraw_website from '../assets/undraw_website2.png'
import { animateScroll as scroll, scroller } from 'react-scroll';


function Work() {

    const [nav, setnav] = useState('');

      const scrollTo = (elementName) => {
        scroller.scrollTo(elementName, {
          duration: 800, // Duration of the scroll animation in milliseconds
          delay: 0, // Delay before the scroll starts in milliseconds
          smooth: 'easeInOutQuart', // Easing function for the scroll animation
        });
      };

      const handleClick = (e) => {  
        if (nav !== e) {
            setnav(e);
            scrollTo('handleDetails'); // Scroll to the element when the button is clicked
          } else {
            setnav('');
          }
        //navigate("/ubuntu"); // Replace '/my-page' with the correct URL or route path
      };

      const handleDetails = () =>{
        if(nav === 'server'){
          return <Server/>
        }
        if(nav ==='website'){
          return <Website/>
        }
      }

  return (
    <>
    <div name='work' className='w-full md:h-screen text-gray-300 bg-[#0a192f]'>
        <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
            <div className='pb-8'>
                <p className='text-4xl font-bold inline border-b-4 text-gray-300 border-pink-600'>Projets</p>
                <p className='py-6'>Découvrez certains de mes travaux récents.</p>
            </div>

            {/* Container */}
            <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-4'>

                {/* Grid Item */}
                <div style={{backgroundImage:`url(${undraw_server})`}} className=' shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'>
                    
                    {/* Hover Effects */}
                    <div className='opacity-0 group-hover:opacity-100 text-center'>
                        <span className='text-2xl font-bold  text-white tracking-wider'>
                            Configuration d'un serveur multimédia
                        </span>
                         <div className='pt-8 text-center'>
                                <button onClick={() => handleClick("server")} className='text-center ronded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Détails</button>
                         </div>
                    </div>
                </div>
                {/* Grid Item */}
                <div style={{backgroundImage:`url(${undraw_website})`}} className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'>
                    
                    {/* Hover Effects */}
                    <div className='opacity-0 group-hover:opacity-100 text-center'>
                        <span className='text-2xl font-bold  text-white tracking-wider'>
                            Création d'une page web porfolio
                        </span>
                         <div className='pt-8 text-center'>
                                <button onClick={() => handleClick("website")} className='text-center ronded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Détails</button>
                         </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
            <div id='handleDetails'>{handleDetails()}</div>
            <p></p>
        </>
  )
}

export default Work