import React, {useState} from 'react'
import Logo from '../assets/logo2.png'
import CV from '../assets/cv.png'
import {FaBars, FaTimes, FaGithub, FaLinkedin, FaFacebook} from 'react-icons/fa'
import {HiOutlineMail} from 'react-icons/hi'
import {BsFillPersonLinesFill} from 'react-icons/bs'
import { animateScroll as scroll, scroller } from 'react-scroll';
import "../navbar.css"
import LoadingIcons from 'react-loading-icons'


const Navbar = () => {

  const [nav, setnav] = useState(false);
  const [loading, setloading] = useState(false)
  const handleClick = () => setnav(!nav);

  const scrollTo = (elementName) => {
    scroller.scrollTo(elementName, {
      duration: 800, // Duration of the scroll animation in milliseconds
      delay: 0, // Delay before the scroll starts in milliseconds
      smooth: 'easeInOutQuart', // Easing function for the scroll animation
    });
  };

  const scrollToMobile = (elementName) => {
    scroller.scrollTo(elementName, {
      duration: 800, // Duration of the scroll animation in milliseconds
      delay: 0, // Delay before the scroll starts in milliseconds
      smooth: 'easeInOutQuart', // Easing function for the scroll animation
    });
    handleClick();
  };

  const handleDownload = () => {

    setloading(true);

    setTimeout(() => {
      setloading(false);

      downloadFile();
    }, 2000)
  };

  const downloadFile = () => {
    const fileUrl = CV; // Replace with your file URL
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = 'Cédrik Letarte curriculum vitae.png'; // Replace with your desired filename
    link.click();
  }
  

  return (
    <div className='fixed w-full h-[80px] flex justify-between items-center px-4 bg-[#0a192f] text-gray-300 navbar'>
        <a href="https://www.catarixe.com">
            <img src={Logo} alt='Logo Image' style={{width: '50px'}}/>
        </a>

        {/* menu  className='py-6 text-4xl' */}
             <ul className='hidden md:flex'>
                <li class="border-effect" onClick={() => scrollTo('home')}>Accueil</li>
                <li class="border-effect" onClick={() => scrollTo('about')}>À propos</li>
                <li class="border-effect" onClick={() => scrollTo('skills')}>Compétences</li>
                <li class="border-effect" onClick={() => scrollTo('work')}>Projets</li>
                <li class="border-effect" onClick={() => scrollTo('contact')}>Contact</li>
             </ul>

        {/* Hamburger */}
        <div onClick={handleClick} className='md:hidden z-10'>
            {!nav ? <FaBars/> : <FaTimes/>}
        </div>

        {/* Mobile Menu */}
        <ul className={!nav ? 'hidden' : 'absolute top-0 left-0 w-full h-screen bg-[#0a192f] flex flex-col justify-center items-center animate-slide-in' }>
            <li class="border-effect" className='py-6 text-4xl' onClick={() => scrollToMobile('home')}>Accueil</li>
            <li class="border-effect" className='py-6 text-4xl' onClick={() => scrollToMobile('about')}>À propos</li>
            <li class="border-effect" className='py-6 text-4xl' onClick={() => scrollToMobile('skills')}>Compétences</li>
            <li class="border-effect" className='py-6 text-4xl' onClick={() => scrollToMobile('work')}>Projets</li>
            <li class="border-effect" className='py-6 text-4xl' onClick={() => scrollToMobile('contact')}>Contact</li>
        </ul>

        {/* Social icons */}
        <div className='hidden lg:flex fixed flex-col top-[35%] left-0'>
          <ul>
            {/*
            <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-blue-600'>
              <a className='flex justify-between items-center w-full  text-gray-300' href='/'>
                Linkedin <FaLinkedin size={30}/>
              </a>
            </li>
  */}
            <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#333333]'>
              <a className='flex justify-between items-center w-full  text-gray-300' target="_blank" href='https://github.com/Cedrik12'>
                Github <FaGithub size={30}/>
              </a>
            </li>
            <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#6fc2b0]'>
              <a className='flex justify-between items-center w-full  text-gray-300' target="_blank" href='https://mail.google.com/mail/u/0/?fs=1&to=cedrikletarte@gmail.com&tf=cm'>
                Email <HiOutlineMail size={30}/>
              </a>
            </li>
{/*
            <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#565f69]'>
              <a className='flex justify-between items-center w-full  text-gray-300' onClick={handleDownload}>
                CV {loading ? <LoadingIcons.ThreeDots width="50px" height="50px" style={{ marginRight: '-10px' }}/> : <BsFillPersonLinesFill size={30}/>}
              </a>
            </li>
*/}
          </ul>
        </div>
    </div>
  )
}

export default Navbar