import React from 'react'
import Ssh from '../assets/ssh.png'
import Sshkey from '../assets/sshkey1.png'
import Google from '../assets/google.png'
import Firewall from '../assets/firewall1.png'

const Ubuntu = () => {
  return (
    <div className='w-full min-h-screen   bg-[#0a192f] text-gray-300 flex justify-center items-center p-4'>
        {/* Container */}
        <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
            <div>
                <p className='text-4xl font-bold inline border-b-4 border-pink-600'>Page Web</p>

                <p style={{textAlign: 'justify'}} className='text-[#8892b0] py-4 max-w[700px]'> J'ai entrepris la conception d'une page web en React dans le but de créer un espace qui pourrait mettre en valeur davantage mes connaissances et compétences. Pour ce faire, j'ai opté pour l'utilisation du gestionnaire de paquets Yarn, qui est un outil puissant permettant de gérer les dépendances de manière efficace dans mon projet. <br/><br/>

                En combinant judicieusement divers frameworks, j'ai pu enrichir l'expérience utilisateur de ma page web : <br/><br/>

                <p style={{fontWeight: 'bold', display: 'inline', fontSize: 20}}>1. </p> React-Scroll : Cette bibliothèque a été utilisée pour implémenter un défilement fluide (smooth scrolling) à travers ma page web. Elle offre des fonctionnalités prêtes à l'emploi pour créer des liens d'ancrage et améliorer la navigation, ce qui permet aux visiteurs de se déplacer facilement vers les différentes sections de la page. <br/><br/>

                <p style={{fontWeight: 'bold', display: 'inline', fontSize: 20}}>2. </p>React-Loading-Icons : Grâce à cette bibliothèque, j'ai pu intégrer des icônes animées de chargement, ce qui permet d'indiquer visuellement aux utilisateurs que des opérations sont en cours. Ces icônes de chargement offrent une expérience plus agréable en informant les visiteurs sur l'état du traitement des informations. <br/><br/>

                <p style={{fontWeight: 'bold', display: 'inline', fontSize: 20}}>3. </p>React-Icons : Cette bibliothèque regroupe un large éventail d'icônes populaires utilisées dans les interfaces utilisateur modernes. En l'intégrant dans ma page web, j'ai pu améliorer l'esthétique et l'accessibilité de l'application en ajoutant des icônes significatives et engageantes. <br/><br/>

                <p style={{fontWeight: 'bold', display: 'inline', fontSize: 20}}>4. </p>React-Typed : Pour rendre la présentation de mes connaissances et compétences plus attrayante, j'ai utilisé React-Typed. Cette bibliothèque facilite l'affichage d'effets de texte tapé (typed.js), créant ainsi un effet dynamique et interactif qui captive l'attention des visiteurs. <br/><br/>

                <p style={{fontWeight: 'bold', display: 'inline', fontSize: 20}}>5. </p>Undraw : Afin d'illustrer visuellement mes contenus de manière attrayante et légale, j'ai utilisé Undraw. Il s'agit d'une collection d'illustrations SVG de haute qualité, toutes sous licence libre de droit (no copyright). Grâce à Undraw, j'ai pu trouver et intégrer des images pertinentes qui complètent efficacement mes textes et améliorent l'expérience visuelle globale. <br/><br/>

                Grâce à cette combinaison de technologies, ma page web en React propose une expérience utilisateur enrichissante, avec des fonctionnalités fluides de défilement, des icônes animées intuitives, une esthétique améliorée grâce aux icônes populaires et une présentation dynamique de mes connaissances et compétences.
                </p>
            </div>
            {/*
            <div className='w-full grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-8'>
                <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                    <img className='w-20 mx-auto' src={Ssh} alt='HTML icon'/>
                    <p className='my-4'>SSH</p>
                </div>
                <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                    <img className='w-20 mx-auto' src={Sshkey} alt='HTML icon'/>
                    <p className='my-4'>SSH-KEYGEN</p>
                </div>
                <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                    <img className='w-20 mx-auto' src={Google} alt='HTML icon'/>
                    <p className='my-4'>2FA GOOGLE AUTHENTICATOR</p>
                </div>
                <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                    <img className='w-20 mx-auto' src={Firewall} alt='HTML icon'/>
                    <p className='my-4'>PARE-FEU</p>
                </div>
            </div>
            */}
        </div>
    </div>
  )
}

export default Ubuntu