import About from "./components/About";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import React from "react";
import Skills from "./components/Skills";
import Work from "./components/Work";
import Contact from "./components/Contact";

function App() {
  return (
    <>
    <Navbar/>
    <Home/>
    <About/>
    <Skills/>
    <Work/>
    <Contact/>
    </>
  );
}

export default App;
